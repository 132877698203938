// Framework
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// Project modules
import { ShareModule } from "@src/app/modules/share/share.module";
// Components
import { CookieInformationComponent } from "./components/cookie-information/cookie-information.component";
import { PrivacyPolicySettingsComponent } from "./components/privacy-policy-settings/privacy-policy-settings.component";

@NgModule({
    declarations: [
        CookieInformationComponent,
        PrivacyPolicySettingsComponent,
    ],
    imports: [
        CommonModule,
        ShareModule,
    ],
    exports: [
        CookieInformationComponent,
        PrivacyPolicySettingsComponent,
    ]
})
export class CookieModule { }
