<div class="container cookie-card">
    <section class="row">
        <div class="col-12">
            <div *ngIf="cookieInformation?.isAccepted != null" class="row">
                <div class="col-6">
                    <span>{{ 'cookie-information.accept' | translate }}</span>
                </div>
                <div class="col-6">
                    <input type="checkbox" [(ngModel)]="cookieInformation.isAccepted">
                </div>
            </div>
            <div *ngIf="cookieInformation?.name != null" class="row">
                <div class="col-6">
                    <span>{{ 'cookie-information.name' | translate }}</span>
                </div>
                <div class="col-6">
                    <span>{{ cookieInformation?.name }}</span>
                </div>
            </div>
            <div *ngIf="cookieInformation?.provider != null" class="row">
                <div class="col-6">
                    <span>{{ 'cookie-information.provider' | translate }}</span>
                </div>
                <div class="col-6">
                    <span>{{ cookieInformation?.provider | translate }}</span>
                </div>
            </div>
            <div *ngIf="cookieInformation?.purpose != null" class="row">
                <div class="col-6">
                    <span>{{ 'cookie-information.purpose' | translate }}</span>
                </div>
                <div class="col-6">
                    <span>{{ cookieInformation?.purpose | translate }}</span>
                </div>
            </div>
            <div *ngIf="cookieInformation?.cookieName != null" class="row">
                <div class="col-6">
                    <span>{{ 'cookie-information.cookie-name' | translate }}</span>
                </div>
                <div class="col-6">
                    <span>{{ cookieInformation?.cookieName }}</span>
                </div>
            </div>
            <div *ngIf="cookieInformation?.cookieDuration != null" class="row">
                <div class="col-6">
                    <span>{{ 'cookie-information.cookie-duration' | translate }}</span>
                </div>
                <div class="col-6">
                    <span>{{ cookieInformation?.cookieDuration | translate }}</span>
                </div>
            </div>
            <div *ngIf="cookieInformation?.privacyPolicyLink != null" class="row">
                <div class="col-6">
                    <span>{{ 'cookie-information.privacy-policy' | translate }}</span>
                </div>
                <div class="col-6">
                    <a [href]="cookieInformation?.privacyPolicyLink">{{ cookieInformation?.privacyPolicyLink }}</a>
                </div>
            </div>
            <div *ngIf="cookieInformation?.hosts != null" class="row">
                <div class="col-6">
                    <span>{{ 'cookie-information.hosts' | translate }}</span>
                </div>
                <div class="col-6">
                    <span>{{ cookieInformation?.hosts }}</span>
                </div>
            </div>
        </div>
    </section>
</div>
