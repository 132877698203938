// Framework
import { Component, Input } from "@angular/core";
// Interfaces
import { CookieInformation } from "@src/app/modules/share/interfaces/cookie-information.interface";

@Component({
    selector: "app-cookie-information",
    templateUrl: "./cookie-information.component.html",
    styleUrls: ["./cookie-information.component.scss"],
})
export class CookieInformationComponent {
    @Input() cookieInformation: CookieInformation = null;

    constructor() { }
}
