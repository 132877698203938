// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Enums
import { LivetickerType } from "../enums/liveticker-type.enum";


@Pipe({
    name: "liveticker"
})
export class LivetickerPipe implements PipeTransform {

    transform(value: LivetickerType): string {
        switch (value) {
            case LivetickerType.general:
                return "Kein Typ";
            case LivetickerType.goal:
                return "Tor";
            case LivetickerType.dismissal:
                return "Dismissal";
            case LivetickerType.final:
                return "Abpfiff";
            case LivetickerType.kickoff:
                return "Anpfiff";
            case LivetickerType.halftime:
                return "Halbzeit";
            case LivetickerType.var:
                return "Var - Entscheidung";
            case LivetickerType.notAllowed:
                return "Tor nicht gegeben";
            case LivetickerType.lineup:
                return "Aufstellung";
            case LivetickerType.ad:
                return "Werbung";
            case LivetickerType.sponsor:
                return "Sponsor";
            case LivetickerType.redCard:
                return "Rote Karte";
            case LivetickerType.yellowCard:
                return "Gelbe Karte";
            case LivetickerType.yellowRedCard:
                return "Gelb-rote Karte";
            case LivetickerType.twitter:
                return "Twitter";
            case LivetickerType.playbackTime:
                return "Nachspielzeit";
            case LivetickerType.injury:
                return "Verletzung";
            case LivetickerType.viewership:
                return "Zuschauerzahl";
            case LivetickerType.waitForKickoff:
                return "Warten auf Kickoff";
            case LivetickerType.youtube:
                return "Youtube";
            case LivetickerType.statistic:
                return "Statistik";
            case LivetickerType.substitute:
                return "Auswechslung";
            // case LivetickerType.ballPossession:
            //     return "Ballbesitz";
            default:
                return "/";
        }
    }
}
