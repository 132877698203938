// Framework
import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Plugins
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxPaginationModule } from "ngx-pagination";
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Project modules
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { ShareRoutingModule } from "./share-routing.module";
// Components
import { ShareComponent } from "./components/share/share.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
// Pipes
import { GenderPipe } from "./pipes/gender.pipe";
import { FieldPositionPipe } from "./pipes/field-position.pipe";
import { CategoryPipe } from "./pipes/category.pipe";
import { LivetickerPipe } from "./pipes/liveticker.pipe";

@NgModule({
    declarations: [
        ShareComponent,
        NotFoundComponent,
        GenderPipe,
        FieldPositionPipe,
        CategoryPipe,
        LivetickerPipe,
    ],
    imports: [
        CommonModule,
        ShareRoutingModule,
        TranslateModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            positionClass: "toast-bottom-right",
            maxOpened: 5,
            toastClass: "",
            iconClasses: {
                success: "alert alert--success",
                error: "alert alert--danger",
                info: "alert alert--info",
                warning: "alert alert--warning"
            },
            // individual
            closeButton: false,
            tapToDismiss: true,
            progressBar: true,
        }),
        NgMultiSelectDropDownModule,
        NgxPaginationModule,
        NgbModule,
    ],
    exports: [
        HttpClientModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        GenderPipe,
        NgMultiSelectDropDownModule,
        NgxPaginationModule,
        FieldPositionPipe,
        CategoryPipe,
        NgbModule,
        LivetickerPipe,
    ],
    providers: [
        DecimalPipe,
        DatePipe,
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    ],
})
export class ShareModule { }
