// Framework
import { Injectable } from "@angular/core";
import { Router, CanActivateChild, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate, CanLoad, Route, UrlSegment } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
// Services
import { SessionApiService } from "../modules/login-module/services/api/session-api.service";
import { BaseGuardService } from "@src/app/route-guards/templates/base-guard.service";
import { AppSettingsService } from "../modules/share/services/app-settings.service";

@Injectable({
    providedIn: "root"
})
export class LoginGuardService extends BaseGuardService implements CanActivate, CanActivateChild, CanLoad {

    constructor(private sessionApi: SessionApiService, protected router: Router, private appSettings: AppSettingsService) {
        super(router);
    }

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.findSession(state.url);
    }

    canActivateChild(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.findSession(state.url);
    }

    canLoad(_: Route, segments: UrlSegment[]): Observable<boolean> {
        const fullPath = segments.reduce((path, currentSegment) => {
            return `${path}/${currentSegment.path}`;
        }, "");

        return this.findSession(fullPath);
    }

    /**
     * This method lets the backend check the current session state and returns the result as observable.
     * @param returnUrl A return url that should be set as query param and reactivated after a successful login.
     * @returns Observable with an boolean value that represents the current session state.
     */
    private findSession(returnUrl: string): Observable<boolean> {
        return this.sessionApi.getSession()
            .pipe(
                map(() => {
                    this.appSettings.setIsLoggedIn(true);
                    return true;
                }),
                catchError((err) => {
                    console.error(err);
                    this.appSettings.setIsLoggedIn(false);
                    if (returnUrl != null) {
                        this.navigateToLogin(returnUrl);
                    }
                    return of(false);
                })
            );
    }
}
