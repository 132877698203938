export enum LivetickerType {
    general = "general", // type 0
    goal = "goal", // type 1
    dismissal = "dismissal", // type 2
    final = "final", // type 3
    kickoff = "kickoff", // type 4
    halftime = "halftime", // type 5
    var = "var", // type 6
    notAllowed = "not-allowed", // type 7
    lineup = "lineup", // type 8
    ad = "ad", // type 9
    sponsor = "sponsor", // type 10
    substitute = "substitute", // type 11
    redCard = "red-card", // type 12
    yellowCard = "yellow-card", // type 13
    yellowRedCard = "yellow-red-card", // type 14
    twitter = "twitter", // type 15
    playbackTime = "playback-time", // type 16
    injury = "injury", // type 17
    viewership = "viewership", // type 18
    waitForKickoff = "wait-for-kickoff", // type 19
    youtube = "youtube", // type 20
    statistic = "statistic", // type 21
    survey = "survey", // type 22
    news = "news", // type 23
    summary = "summary", // type 24
}
