<!-- Modal -->
<div class="modal fade" id="privacy-policy-settings-modal" tabindex="-1" role="dialog" aria-labelledby="privacy-policy-settings-modal-title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content white-card">
            <div class="modal-header">
                <h5 class="modal-title" id="privacy-policy-settings-modal-title">{{ 'privacy-policy-settings.headline' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="modal__close-button">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    {{ 'privacy-policy-settings.description' | translate }}
                </div>
                <div class="mb-3">
                    <button (click)="onSaveClicked()" type="button" class="btn btn--secondary mr-2">{{ 'usaago.form.save' | translate }}</button>
                    <button (click)="onSaveAndAcceptAllClicked()" type="button" class="btn btn--success">
                        {{ 'privacy-policy-settings.accept-all' | translate }}
                    </button>
                </div>
                <div *ngIf="privacyPolicySettingsService.essentialCookies.length > 0" class="mb-2">
                    <a data-target="#show-cookie-information-essential" data-toggle="collapse" tabindex="1" class="d-block clickable" aria-expanded="false">
                        {{ 'privacy-policy-settings.essential' | translate }}({{ privacyPolicySettingsService.essentialCookies.length }})
                        <i class="fa-solid modal__chevron fa-chevron-down"></i>
                        <i class="fa-solid modal__chevron fa-chevron-up"></i>
                    </a>
                    <div class="collapse" id="show-cookie-information-essential">
                        <ng-container *ngFor="let cookieInformation of privacyPolicySettingsService.essentialCookies">
                            <app-cookie-information [cookieInformation]="cookieInformation"></app-cookie-information>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="privacyPolicySettingsService.copyOfExternalCookies.length > 0">
                    <a data-target="#show-cookie-information-external" data-toggle="collapse" tabindex="2" class="d-block clickable" aria-expanded="false">
                        {{ 'privacy-policy-settings.extern-media' | translate }}({{ privacyPolicySettingsService.copyOfExternalCookies.length }})
                        <i class="fa-solid modal__chevron fa-chevron-down"></i>
                        <i class="fa-solid modal__chevron fa-chevron-up"></i>
                    </a>
                    <div class="collapse" id="show-cookie-information-external">
                        <ng-container *ngFor="let cookieInformation of privacyPolicySettingsService.copyOfExternalCookies">
                            <app-cookie-information [cookieInformation]="cookieInformation"></app-cookie-information>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
