// Framework
import { Injectable } from "@angular/core";
// Plugins
import { NgcCookieConsentService, NgcLayouts, NgcHTMLElements } from "ngx-cookieconsent";
import { TranslateService } from "@ngx-translate/core";
// Global
import { cookieAcceptedName } from "@src/app/global";
// Interfaces
import { CookieInformation } from "@src/app/modules/share/interfaces/cookie-information.interface";

@Injectable({
    providedIn: "root"
})
export class CookieConsentBannerService {
    essentialCookies: CookieInformation[] = [
        {
            id: "1",
            name: "Cookie Accepted",
            provider: "Eigentümer der Seite",
            purpose: "Speichert die Einstellungen der Besucher, ob die Cookie-Benachrichtigung gelesen wurde.",
            cookieName: cookieAcceptedName,
            cookieDuration: "7 Tage",
        },
        {
            id: "2",
            name: "Session",
            provider: "Eigentümer der Seite",
            purpose: "Speichert die Einstellungen der Besucher, die sich erfolgreich eingeloggt haben.",
            cookieName: "session",
            cookieDuration: "1 Jahr",
        },
        {
            id: "3",
            name: "Session SIG",
            provider: "Eigentümer der Seite",
            purpose: "Speichert die Signatur des Session-Cookies.",
            cookieName: "session.sig",
            cookieDuration: "1 Jahr",
        },
        {
            id: "4",
            name: "CSRF Token",
            provider: "Eigentümer der Seite",
            purpose: "Verhindert Cross Site Request Forgery (CSRF).",
            cookieName: "csrf-token",
            cookieDuration: "Sitzung",
        },
        {
            id: "5",
            name: "CSRF",
            provider: "Eigentümer der Seite",
            purpose: "Verhindert Cross Site Request Forgery (CSRF).",
            cookieName: "_csrf",
            cookieDuration: "Sitzung",
        },
        {
            id: "6",
            name: "SMB-App-Settings",
            provider: "Eigentümer der Seite",
            purpose: "Speichert die Informationen vom momentan eingeloggten Nutzer.",
            cookieName: "smb-app-settings",
            cookieDuration: "1 Woche",
        },
    ];
    externalCookies: CookieInformation[] = [];

    constructor(
        private cookieConsentService: NgcCookieConsentService,
        private translateService: TranslateService,
    ) { }

    initBanner(): void {
        // Support for translated cookies messages
        this.translateService.onLangChange
            .subscribe(
                ({ translations }) => {
                    this.cookieConsentService.getConfig().content = this.cookieConsentService.getConfig().content || {};
                    // Override default messages with the translated ones
                    this.cookieConsentService.getConfig().content.header = translations["cookie"]["header"];
                    this.cookieConsentService.getConfig().content.message = translations["cookie"]["message"];
                    this.cookieConsentService.getConfig().content.dismiss = translations["cookie"]["dismiss"];
                    this.cookieConsentService.getConfig().content.allow = translations["cookie"]["allow"];
                    this.cookieConsentService.getConfig().content.deny = translations["cookie"]["deny"];
                    this.cookieConsentService.getConfig().content.link = translations["cookie"]["link"];
                    this.cookieConsentService.getConfig().content.policy = translations["cookie"]["policy"];
                    this.cookieConsentService.getConfig().layout = "custom-layout";
                    this.cookieConsentService.getConfig().layouts = this.generateLayouts();
                    this.cookieConsentService.getConfig().elements = this.generateElements(
                        translations["cookie"]["link"],
                        translations["cookie"]["policy-settings"]
                    );

                    this.cookieConsentService.destroy(); // remove previous cookie bar (with default messages)
                    this.cookieConsentService.init(this.cookieConsentService.getConfig()); // update config with translated messages
                }
            );
    }

    private generateLayouts(): NgcLayouts {
        return {
            "custom-layout": "{{messagelink}}{{compliance}}"
        };
    }

    private generateElements(policyText: string, policySettings: string): NgcHTMLElements {
        return {
            messagelink: `
                <span id="cookieconsent:desc" class="cc-message">
                    <div>
                        {{message}}
                    </div>
                    <div class="d-block d-sm-flex mt-1">
                        <a
                            aria-label="learn more about cookies"
                            role="button"
                            tabindex="0"
                            class="cc-link text-uppercase"
                            href="privacy-policy"
                            rel="noopener noreferrer nofollow"
                            target="_blank"
                        >
                            ${policyText}
                        </a>
                        <span class="cookie-banner-link cookie-banner-link--separator d-none d-sm-block"> | </span>
                        <a class="cc-link text-uppercase" data-toggle="modal" data-target="#privacy-policy-settings-modal">${policySettings}</a>
                    </div>
                </span>
            `,
        };
    }
}
