<div class="container">
    <header class="row">
        <div class="col-12">
            <h3 class="text-center accent-font-color-mid">{{ 'not-found.not-found' | translate }}</h3>
        </div>
    </header>
    <section class="row justify-content-center">
        <div class="col-12">
            <p class="text-center mt-3"> {{ 'not-found.subtext-0' | translate }} <br>
                {{ 'not-found.subtext-1' | translate }}
            </p>
        </div>
    </section>
</div>
