// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Enums
import { AllowedMessageCategories } from "../enums/allowed-message-categories";

@Pipe({
    name: "category"
})
export class CategoryPipe implements PipeTransform {

    transform(value: AllowedMessageCategories): any {
        switch (value) {
            case AllowedMessageCategories.news:
                return "category-pipe.news";
            case AllowedMessageCategories.matchEvents:
                return "category-pipe.match-events";
            case AllowedMessageCategories.voting:
                return "category-pipe.voting";
            case AllowedMessageCategories.stadionServices:
                return "category-pipe.stadion-services";
            case AllowedMessageCategories.promotions:
                return "category-pipe.promotions";
            default:
                return null;
        }
    }
}
