// Framework
import { Component } from "@angular/core";
// Services
import { PrivacyPolicySettingsService } from "../../services/privacy-policy-settings.service";

declare const $: any;

@Component({
    selector: "app-privacy-policy-settings",
    templateUrl: "./privacy-policy-settings.component.html",
    styleUrls: ["./privacy-policy-settings.component.scss"],
})
export class PrivacyPolicySettingsComponent {

    constructor(public privacyPolicySettingsService: PrivacyPolicySettingsService) { }

    /**
     * This method triggers if the user clicked the save button in the view. It patches the current state to the cookie consent service.
     */
    onSaveClicked(): void {
        this.privacyPolicySettingsService.patchCurrentStateToCookieConsent();
        this.closeModal();
    }

    /**
     * This method triggers if the user clicked the accept-all button in the view. It accepts each cookie and patches the current state to the cookie consent
     * service.
     */
    onSaveAndAcceptAllClicked(): void {
        this.onAcceptAllClicked();
        this.onSaveClicked();
    }

    /**
     * This method returns the result of whether all external cookies are accepted.
     * @returns The result of whether all external cookies are accepted
     */
    isEachExternalCookieAccepted(): boolean {
        return this.privacyPolicySettingsService.copyOfExternalCookies.every((value) => {
            return value.isAccepted === true;
        });
    }

    /**
     * This method triggers if the user checks or dischecks all cookies. It calls the privacyPolicySettingsService to set the corresponding values.
     */
     private onAcceptAllClicked(): void {
        const tmpValue = !this.isEachExternalCookieAccepted();
        this.privacyPolicySettingsService.patchAllCopyOfExternalCookies(tmpValue);
    }

    /**
     * This method uses the jquery selector and hides the privacy policy settings modal.
     */
    private closeModal(): void {
        $("#privacy-policy-settings-modal").modal("hide");
    }
}
