// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Enums
import { Gender } from "../enums/gender.enum";

@Pipe({
    name: "gender"
})
export class GenderPipe implements PipeTransform {

    transform(value: Gender): string {
        switch (value) {
            case Gender.male:
                return "gender-pipe.male";
            case Gender.female:
                return "gender-pipe.female";
            case Gender.diverse:
                return "gender-pipe.diverse";
            default:
                return null;
        }
    }
}
